<template lang="pug">
div(class='external-project-item-form')
  modal-container
    modal(v-if='imageUrlImageVisible' size='sm' @close='imageUrlImageVisible = false')
      template(#default='{ close }')
        modal-header {{ $t('Choose image from URL') }}
        overlay(:visible='imageUrlLoading' class='rounded-xl')
        form(@submit.stop.prevent='submitImageByUrl()')
          notification(class='-mt-2 mb-2') 
            span(v-html='$t("Right-click an image in the browser to copy the image URL.")')
          control-label(:error='imageUrlError')
            control-input(v-model='imageUrlLocal' size='large' data-test='input-image-url' :placeholder='$t("http://example-brand.com/example-furniture.jpg")' class='mb-2')
            notification(v-if='imageUrlError' type='error' )
              span(v-html='$t("Image-Url not valid")')
          action-bar
            btn(tertiary @click='close') {{ $t('Cancel') }}
            btn(type='submit' data-test='btn-submit-image-url') {{ $t('Ok') }}
  div(class='mb-2 grid gap-10 sm:grid-cols-2')
    div(v-if='breakpoints.sm' class='flex flex-col pt-5')
      div(class='relative flex flex-1 rounded-lg bg-grey-50')
        overlay(:visible='loadingImage')
        div(v-if='s3ImagePath' class='relative m-4 flex flex-1 items-center justify-center')
          img(v-image-lazy='true' data-test='img-external-product-image' :data-src='"/Products/Assets/" + s3ImagePath' data-cloudinary='t_md' class='absolute h-auto w-auto max-w-full max-h-full object-contain mix-blend-multiply')
        control-file-upload#AssetUpload(
          v-else
          icon='custom-file-image'
          :disabled='disableCommonFields'
          :placeholder='$t("Drop image file here or <a>browse</a>.")'
          :endpoint='`/_/UploadAsset/UploadAsset`'
          accept='image/*'
          class='h-full flex-1 rounded-lg'
          centered
          @uploadEnd='onFileUpload'
        )
          template(#inner)
            div(class='flex w-full flex-col items-center justify-center pb-20')
              div(class='relative mx-2 mb-6 mt-4 flex w-full max-w-[200px] items-center py-5')
                div(class='flex-grow border-t border-grey-100')
                span(class='mx-3 flex-shrink text-grey-400') {{ $t('or') }}
                div(class='flex-grow border-t border-grey-100')
              btn(secondary :disabled='disableCommonFields' icon='external-link' class='mb-10 mix-blend-multiply' data-test='btn-add-image-via-url' @click='showAddImageByUrl') {{ $t('add image from url') }}
    div
      modal-header(class='mb-4')
        slot(name='header')
      overlay(:visible='loadingOpenGraphData')
      form-hidden(name='Project' :modelValue='contextId')
      form-input(v-model='productUrl' name='ProductUrl' class='-mt-3' :disabled='disableCommonFields' :note='isModeEdit ? "" : $t("Use this form to add a product from an external supplier.")' autofocus)
      form-input(name='Name' :modelValue='presetData.title' :disabled='disableCommonFields')
      div(class='sm:grid sm:grid-cols-2 sm:gap-4')
        form-select-async(
          v-model='currentBrand'
          name='Brand'
          :disabled='disableCommonFields'
          :controlProps='{ fetchOptions, options: brandOptions, creatable: true, placeholderCreatable: (value: any) => $t("Choose other brand: <b>{0}</b>", "", [value]) }'
        )
        form-input(name='ProductNumber' :markOptional='true' :disabled='disableCommonFields')
      div(class='sm:grid sm:grid-cols-2 sm:gap-4')
        form-input-money(v-model='priceLocal' name='Price' :disabled='disablePriceFields')
          | {{ $t('€') }}
        form-select-native(v-model='isNet' name='IsNet' :disabled='disablePriceFields')
      slot(name='belowPrice')
      form-textarea(
        name='Description'
        :label='$t("Product information")'
        :note='$t("Add colors, materials and dimensions, etc.")'
        :markOptional='true'
        :modelValue='presetData.description'
        :disabled='disableCommonFields'
      )
      form-hidden(v-model='s3ImagePath' name='S3Path')
      slot
  form-footer
    template(#secondary)
      btn(v-if='s3ImagePath && !disableCommonFields' class='-ml-2' plain-danger icon='x' data-test='btn-remove-image' @click='s3ImagePath = ""') {{ $t('remove image') }}
    slot(name='actions')
</template>

<script setup lang="ts">
import ControlFileUpload from '@/components/Control/ControlFileUpload.vue'
import { type UpdateFn } from '@/components/Control/ControlMultiSelectAsync.vue'
import { type IFormResponse } from '@/components/GenericForm/types'
import { useCustomBreakpoints } from '@/composables/'
import { usePermissions } from '@/plugins/Permissions'
import { type IOption, type IPaginatedResponse } from '@/types'
import { parseNumber, isValidUrl, addImageFromUrl } from '@/utilities'
import { debouncedWatch } from '@vueuse/core'
import axios from 'axios'
import { isNumber } from 'radash'
import { onMounted, ref, watch, reactive, type PropType } from 'vue'

interface IOpenGraphDataResponse {
  description: string
  image: string
  images: string[]
  price: string | number
  title: string
  url: string
}

interface IOpenGraphData {
  description: string
  image: string
  title: string
  url: string
}

const fetchOptions = async (query: string = '', update?: UpdateFn) => {
  const { data } = await axios.get<IPaginatedResponse<{ id: string; title: string }>>(`/_/BrandListForDropdown`, {
    params: { params: { q: query, from: 0, size: 40 } },
  })
  const options = data.hits.map((hit) => ({ value: hit.id, label: hit.title }))
  update?.(options)
  return options
}

const getImageSizeByUrl = async (url: string = '') => {
  const img = new Image()
  const promise = new Promise((resolve) => {
    img.onload = () => resolve([img.width, img.height, url])
    img.onerror = () => resolve([0, 0, url])
  })
  img.src = url
  return promise as Promise<[number, number, string]>
}
const getImages = (urls: string[]) => {
  return Promise.all(urls.map((url) => getImageSizeByUrl(url)))
}

const props = defineProps({
  contextId: { type: String, required: true },
  isModeEdit: Boolean,
  disableCommonFields: Boolean,
  disablePriceFields: Boolean,
  submit: Function as PropType<(action?: string) => void>,
})
defineOptions({ inheritAttrs: false })

const productUrl = ref('')
const s3ImagePath = ref('')

const isNet = defineModel<'true' | 'false'>('isNet')
const priceLocal = defineModel<number>('price', { default: 0 })
const loadingOpenGraphData = ref(false)

const request = async (url: string = '', useProxy: boolean = false) => {
  try {
    return axios.get<any>(
      `https://opengraph.io/api/1.1/site/${encodeURIComponent(url)}?app_id=4b97823e-dda0-42a1-9ea4-478fbbce6fa1&use_proxy=${useProxy}`,
    )
  } catch (e) {
    return false
  }
}
const brandOptions = ref<IOption[]>([])

const fetchOpenGraphInformation = async (url: string = '') => {
  loadingOpenGraphData.value = true
  let response = await request(url, false)
  if (!response) {
    // try again with proxy
    response = await request(url, true)
  }
  const openGraphIOResponse = response ? response.data : {}
  return {
    description: openGraphIOResponse?.hybridGraph.description || '',
    image: openGraphIOResponse?.hybridGraph.image || '',
    images: openGraphIOResponse?.htmlInferred.images || [],
    title: openGraphIOResponse?.hybridGraph.title || '',
    url,
    price: openGraphIOResponse?.hybridGraph?.products?.[0]?.offers?.[0]?.price || 0,
  } as IOpenGraphDataResponse
}
const fetchPossibleBrands = async () => (await axios.get<any>(`/brands.json`)).data as string[]

const presetData = reactive<IOpenGraphData>({
  description: '',
  image: '',
  title: '',
  url: '',
})

const $permissions = usePermissions()

const loadingImage = ref(false)

const currentBrand = ref('')

if ($permissions.canAccessAdminMenu) {
  onMounted(() => {
    watch(
      () => currentBrand.value,
      (newValue) => {
        if (newValue !== '') {
          props.submit?.('EditExternalInquiryListItemForm/UpdateInquiryListItem')
        }
      },
    )
  })
}

const submitImageToS3 = async (url: string) => {
  loadingImage.value = true
  try {
    s3ImagePath.value = await addImageFromUrl(url)
  } catch (e) {}
  loadingImage.value = false
}
const isProbablyProductImage = (url: string) =>
  !url.includes('.svg') && !url.includes('logo') && !url.includes('favicon')
const hasAcceptableImageDimensions = (w: number, h: number) => w > 200 && h > 200

const isImageAcceptable = async (url: string) => {
  if (!isProbablyProductImage(url)) {
    return false
  }
  const [w, h] = await getImageSizeByUrl(url)
  if (!hasAcceptableImageDimensions(w, h)) {
    return false
  }

  return true
}

onMounted(() =>
  debouncedWatch(
    () => productUrl.value,
    async (newValue) => {
      if (!!newValue && isValidUrl(newValue)) {
        try {
          const { image, title, images, url, price, description } = await fetchOpenGraphInformation(newValue)
          const brands = await fetchPossibleBrands()
          const relevantOgFieldsAsString = `${title} ${url} ${description}`.toLowerCase()
          const brandMatchFromLookupTable =
            brands.find((brandName) => relevantOgFieldsAsString.includes(brandName.toLowerCase())) || ''
          if (brandMatchFromLookupTable) {
            brandOptions.value = await fetchOptions(brandMatchFromLookupTable)
            // @TODO: include check should not be necessary, unfortunately the endpoint in fetchOptions does not actually filter
            const matchedOption = brandOptions.value.find((option) =>
              option.label.toLowerCase().includes(brandMatchFromLookupTable.toLowerCase()),
            )
            currentBrand.value = matchedOption ? (matchedOption.value as string) : brandMatchFromLookupTable
          }
          presetData.image = presetData.image || image
          presetData.title = presetData.title || title
          presetData.description = presetData.description || description
          presetData.url = presetData.url || url
          priceLocal.value =
            priceLocal.value && Number(priceLocal.value) !== 0
              ? priceLocal.value
              : price
                ? isNumber(price)
                  ? price
                  : parseNumber(price)
                : priceLocal.value
          loadingOpenGraphData.value = false

          if (await isImageAcceptable(image)) {
            // if suggested image upload that one
            await submitImageToS3(image)
          } else {
            // else check alternative images
            const sortedAndFilteredImageSizes = (await getImages(images.filter(isProbablyProductImage)))
              .filter(([w, h]) => hasAcceptableImageDimensions(w, h))
              .sort(([w1, h1], [w2, h2]) => w2 + h2 - (w1 + h1))
            if (sortedAndFilteredImageSizes.length > 0) {
              await submitImageToS3(sortedAndFilteredImageSizes[0][2])
            }
          }
        } catch (e) {
          loadingOpenGraphData.value = false
        }
      }
    },
    { debounce: 300 },
  ),
)
const imageUrlImageVisible = ref(false)
const imageUrlLocal = ref('')
const imageUrlError = ref(false)
const imageUrlLoading = ref(false)
watch(
  () => imageUrlLocal.value,
  () => (imageUrlError.value = false),
)

const showAddImageByUrl = () => (imageUrlImageVisible.value = true)

const submitImageByUrl = async () => {
  if (isValidUrl(imageUrlLocal.value)) {
    imageUrlLoading.value = true
    try {
      s3ImagePath.value = await addImageFromUrl(imageUrlLocal.value)
      imageUrlImageVisible.value = false
    } catch (e) {
      imageUrlError.value = true
    }
    imageUrlLoading.value = false
  } else {
    imageUrlError.value = true
  }
}
const breakpoints = useCustomBreakpoints()
const onFileUpload = (response: IFormResponse<string>) => (s3ImagePath.value = response.data!)
</script>
<style>
.external-project-item-form .control-file__label {
  justify-content: flex-end !important;
}
</style>
